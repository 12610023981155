                .App {
  text-align: center;
   height: 100vh;
   width: 100vw;
   font-family: 'Roboto', sans-serif;
 
  
   
}
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.card{
  width: 300px;
  height:auto;
}

.bill-logo{
  width: 55px;
  height: 55px;
}
.details .card{
  height: auto !important;
}
.row-name{
  
  max-width: 75%;
}
.row-info{
  min-width: 50px;
}
.form-control{
  margin: auto;
  max-width: 650px;
}
.blog{
  max-width: 600px;
  text-align: left;
  margin-top: 20px;
  height: auto;
  
  
}
.bill-detail-container{
  max-width: 595px;
  min-height: 842px;
}
.productPic{
  width: 150px;
}
.f-1 .footer-container{
  width: 25% !important;
}
.blog-container{
  max-width: 100%;
  
}
.blog-img{
  max-width: 100%;
} 
.blog-img-container{
  text-align: center;
}
.blog-blog{
  white-space: pre-wrap;
}

body{
  
  background-color: rgb(190, 216, 255);
}
.searchBar{
  background-color: rgb(247, 245, 245) !important;
  height: 40px;
  min-width: 260px !important;
  
}

.Login .card{
  min-width: 350px !important;
  margin: auto;
}
.Register .card{
  min-width: 350px !important;
  margin: auto;
}
.details .card{
  min-width: 350px !important;
  margin: auto;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.carousel-control-next-icon,.carousel-control-prev-icon{
  background-color: #000000;
  border-radius: 10px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-footer{
  background-color: rgb(253, 218, 223) !important;
}
.admin-sidebar{
  
  width: 200px;
  background-color: aliceblue;
  height: 100vh;
}
.dec-none{
  text-decoration: none;
}

.left-img{
  max-width: 80px;
  min-width: 30px;
}
.right-img{
  max-width: 370px;
  min-width: 200px;
}
.margin-right-0{
  margin: 0px !important;
}
.h-300{
  height: 100px;
}
.cartImg{
  width:90%;
  max-width:160px ;

}
.box1{
   height: 200px;
   width: 300px;
   background-color: rgb(86, 122, 221);
   border:1px solid rgb(197, 197, 197);
   margin: 30px;
}
.box2{
  height: 200px;
  width: 300px;
   background-color: rgb(59, 182, 96);
   border:1px solid rgb(197, 197, 197);
   
   margin: 30px;
}

.bold{
  font-weight: bold;
}
.not-bold{
  font-weight:500;
}
.width-400{
width:370px ;

}

.shokhi-btn1{
  width: 240px;
  height: 40px;
  margin: 10px;
} 
.shokhi-btn{
  width: 180px;
  height: 30px;
  margin: 10px;
  align-items: center;
  font-size: 13px !important;
 
} 
.card-cart{
  height: 100px !important;
}
.box-icon{
  width: 30px;
  position: relative;
  right: 5px;
}
.home-icon{
  width: 50px;
  padding-top: 10px;
  
}
 
.b1{
  border-radius: 20px;
}

body::-webkit-scrollbar {
  width: 0rem; /* Set the width of the pseudo-scrollbar to zero */
}

body::-webkit-scrollbar-track {
  background: transparent; /* Hide the scrollbar track */
}

body::-webkit-scrollbar-thumb {
  background: transparent; /* Hide the scrollbar thumb */
}
.NavBar{
  background-color: #a73b5b;
  width: 100%;
 
  height: 70px;
  z-index: 10;
  position: sticky;
  top:0px;

  
}

.navbar{
  background-color:#d081e4;
  border: 1px solid pink;
  border-radius: 30px 30px 0px 0px;
  z-index: 10;
  position: relative;
}
.sidebar .navbar{
  background-color: transparent !important;
  border-radius: 0px;
  z-index: 0;
  
}
.homeLink:hover{
  
  background-color: #f2ebf9;
}
.homeLink .dec-none{
  color: black;
}
.sidebar .nav-link{
  font-size: larger;
  font-weight: bold;
  margin-left: 15px;
  display: inline;
  padding: 0px;

}
.loading{
  width: 500px;
  height: 100px;
}
input[type="text"], textarea {

  background-color : #f4f4f4; 

}
.admin-sidebar-open{
  width: 40px;
  border-radius: 20px;
  background-color: #ffffff;
}
.dash-button{
  margin:auto;
}
.admin-sidebar-close{
  width: 30px;
  background-color: transparent;
}
.admin-btn{
  background-color: transparent;
  border: 0px solid black;
  
}

.sidebar .dropdown-menu{
  background-color: transparent;
  border: 0px;
  font-size: large;
}
.sidebar .dropdown-item{
   border-radius: 10px;
}

.trendingContainer{
  
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.sidebar-container{
  overflow-y: scroll;
}
.sidebar-container::-webkit-scrollbar {
  width: 0.5rem;
}

.sidebar-container::-webkit-scrollbar-track {
  background: #818181;
  border-radius: 5px;
}
.sidebar-icon{
  width: 30px;
}

.cart{
  width: 40px;
}
.card{
  max-width: 180px;
  min-height: 430px;
}
.card-body span,del{
  font-size: small;
}
.logo{
  width: 74px;
  
}
.about{
  max-width: 360px;
}
.innerContainer{
  background-color: rgb(205, 247, 255);
   min-height: 100vh;
   width: 100%;
   
}
.home-wrapper{
  height: 92.4vh;
  background-image: url('./images/home.jpg');
  background-size:cover;
}
.collapse{
  background-color: #fcedf3;
  border-radius: 10px;
  position: relative;
  top:4px;
}
.search{
  position: relative;
  right: 1px;
}
.details{
  min-width:350px !important;
}
.details .card-body{
  background-color: #f8fcff !important;
}

.cartAmount{
  
 
  height: 25px;
  width: 30px;
  font-weight: bold;
  border-radius:40px;
  border: 2px solid rgb(126, 126, 126);
  background-color: #61dafb;
  position: relative;
  right: 10px;
  top:-5px;
  

}
.color{
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 2px solid black;
}
.admin-bg{
  background-color: rgb(235, 226, 255);
}
.admin-bg-light{
  background-color: rgb(39, 42, 73);
}
.cart-card{
  min-height: 520px !important;
}
.color-selected{
  height: 40px;
  width: 40px;
  border: 3px solid rgb(51, 207, 200);
 
}
.productPage{
  min-width: 300px !important;
}

.adminProduct{
  width: 200px;
}
.eachProduct{
  background-color: #ded0ff;
  width: 200px;
  border: 1px solid gray;
  border-radius: 10px;
  height: 350px;
}
.navbar-nav{
  width: 100%;
}
.sidebar-btn{
  height: 55px;
  width: 100%;
  background-color:#d081e4;
  border-radius: 30px 30px 0px 0px;
}
.sidebar-icon-container{
  background-color: transparent;
  border: 0px;
}
 
.admin-sidebar-btn{
  height: fit-content;
  width: 100%;
  background-color:#1a1b70;
  border-radius: 00px 0px 0px 0px;
}
.plus-icon{
  width: 18px;
  position: relative;
  top:-2px;
  margin-left: 8px;
}
.sidebar {
    height: 91vh;
    width: 300px;
    background-color: rgb(247, 194, 234);
    border-radius: 0px 30px 0px 30px;
    position: absolute;
    top: 59px;
    left:-300px;
    transition: left 0.3s ease; /* Adding a transition effect */
    z-index: 12;
    
}
.navItem{
  color:#5b86fc;
}

.admin-sidebar{
  height: 775px;
  width: 300px;
  background-color: rgb(163, 70, 98);
  border-radius: 0px 30px 0px 30px;
  position: absolute;
 
  left:-300px;
  transition: left 0.3s ease; /* Adding a transition effect */
  z-index: 3;
}
.admin-sidebar a{
  
  text-align: left;
  color: black;
  font-size:larger;
}
.admin-sidebar a,.admin-sidebar h3{
  margin-left: 30px;
}
 .admin-sidebar h3{
  color: #ffe3e3;
}
.Finance{
  height: 100vh;
width: 100%;
  background-image: url('./images/dashboard.jpg');
  background-size: cover;
}
.sidebar-show{
  left:0px;
}
.master{
    min-height: 100vh;
}
.item-row{
  min-width: 300px; 
  max-width: 600px;
  height: 120px;
  
  margin-left: 25px;
  margin-right: 25px;

  border-bottom:1px solid rgb(185, 184, 184);
}
.shokhiBox-container{
   margin-top: 30px;
  border: 3px solid gray;
  border-radius: 20px;
  max-width: 615px;
  min-width: 340px; 

  
}
.item-name{
  width: 70px;
  
}
.eachBox{
  max-width: 800px;
  border-radius: 40px;
  margin: auto;
  margin-top: 20px;
}
.item-details{
 
  width: 70%;
}
.shokhi-pic-container{
  
  width: 88px;
  min-width: 65px;
}
.pics{
  max-width: 300px;
}
.shokhi-pic{
  width: 70%;
}
.cart-item-row{
  
  min-width: 100%;
}
.box-name{
  max-width: 180px;
}
 .Outer-Container{
  margin-top: 34px;
 }
.homeLink{

  height: 100px;
  width: 18%;
  
}
.b1{
  width: 100%;
}
@media screen and (max-width: 800px) {
   *{
      font-size:14px;
   }

}
@media screen and (min-width: 583px) {
  .dash-button{
    margin:0px;
  }

}

@media screen and (max-width: 415px) {
  .item-row{
    min-height: 130px;
  }

}
@media screen and (max-width: 423px) {
  .item-row{
    min-height: 150px;
  }

}
@media screen and (max-width: 411px) {
  .item-row{
    min-height: 180px;
  }

}
.contents{
  margin-top: 0px;
}
.footer-outer{
  display: flex;
   
  
}
.sans{
  font-family:'Segoe UI'  !important;
}
.footer-container{
  margin-bottom: 20px;
}
@media screen and (max-width: 800px) {
  .footer-outer{
     display: flex;
     flex-direction: column;
  }

}
@media screen and (max-width: 990px) {
.trendingContainer{
 
  flex-wrap: wrap;
}

}
@media screen and (min-width: 580px) {
.tredningProduct1 .card{
 
  min-width: 200px;
}

}
@media screen and (min-width: 642px) {
.tredningProduct1 .card{
 
  min-width: 220px;
}

}
@media screen and (min-width: 701px) {
.tredningProduct1 .card{
 
  min-width: 240px;
}

}
@media screen and (min-width: 762px) {
.tredningProduct1 .card{
 
  min-width: 130px;
}

}
@media screen and (max-width: 388px) {
.tredningProduct1 .card{
 
  width: 129px !important;
}

}

@media screen and (max-width: 388px) {
.tredningProduct1 .card{
 
  min-width: 120px !important;
  font-size:x-small;
}
h5{
  font-size: x-small;
}

}
.blog-lg{
  display: none ;
}
@media screen and (min-width: 992px) {
  

}
@media screen and (min-width: 991px) {
  .blog-lg{
    display:block ;
 }

}

 



.footer-container{
   
  margin-left: 10px;
  margin-right: 10px;
}
p{
  margin-bottom: 0px !important;
}
.color-yellow{
  color:rgb(130, 130, 51)
}
.card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card-body{
 
  padding: 10px !important;
}
.card-img{
  width: 200px !important;
}